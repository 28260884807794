export const getMainDomain = (
  domain: string | undefined,
  isCT?: boolean,
  isEGE?: boolean,
  isOGE?: boolean,
  isVPR?: boolean,
  isNMT?: boolean,
  isZNO?: boolean,
  isENT?: boolean,
  isOLYMP?: boolean,
  isUROK?: boolean,
): string => {
  let mainDomain = domain || ''
  let separatorI = mainDomain?.indexOf('-')

  if (isCT) {
    const isMainPage = mainDomain?.indexOf('reshu.by') === 0

    if (!isMainPage) {
      separatorI = mainDomain?.indexOf('.')
    }
  }

  if (isEGE) {
    const isMainPage = mainDomain?.indexOf('reshuege.ru') === 0

    if (!isMainPage) {
      separatorI = mainDomain?.indexOf('.')
    }
  }

  if (isOGE) {
    const isMainPage = mainDomain?.indexOf('reshuoge.ru') === 0

    if (!isMainPage) {
      separatorI = mainDomain?.indexOf('.')
    }
  }

  if (isVPR) {
    const isMainPage = mainDomain?.indexOf('reshuvpr.ru') === 0

    if (!isMainPage) {
      separatorI = mainDomain?.indexOf('.')
    }
  }

  if (isNMT) {
    const isMainPage = mainDomain?.indexOf('skladunmt.online') === 0

    if (!isMainPage) {
      separatorI = mainDomain?.indexOf('.')
    }
  }

  if (isZNO) {
    const isMainPage = mainDomain?.indexOf('skladuzno.online') === 0

    if (!isMainPage) {
      separatorI = mainDomain?.indexOf('.')
    }
  }

  if (isENT) {
    const isMainPage = mainDomain?.indexOf('reshuent.ru') === 0

    if (!isMainPage) {
      separatorI = mainDomain?.indexOf('.')
    }
  }

  if (isOLYMP) {
    const isMainPage = mainDomain?.indexOf('reshuolymp.ru') === 0 || mainDomain?.indexOf('olymp.sdamgia.ru') === 0

    if (!isMainPage) {
      separatorI = mainDomain?.indexOf('.')
    }
  }

  if (isUROK) {
    const isMainPage = mainDomain?.indexOf('reshuurok.ru') === 0

    if (!isMainPage) {
      separatorI = mainDomain?.indexOf('.')
    }
  }

  if (separatorI !== -1) {
    mainDomain = mainDomain.slice(separatorI + 1) || ''
  }

  return mainDomain
}
