import React, { FC, lazy, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { useDetectAdBlock } from 'adblock-detect-react'

import { selectSubject } from 'modules/Subject'
import { selectAuthStatus } from 'modules/Auth'
import { getDomain } from 'utils'

import Student from 'pages/StudentPage'

import Spinner from 'components/Spinner'
import Portal from 'components/Portal'
import AlertList from 'components/AlertList'

import { useGeneralData } from './hooks'
import ContentFallback from './components/ContentFallback'
import AlertListChat from './components/Alerts/AlertListChat'
import AlertListAuth from './components/Alerts/AlertListAuth'
import RecoveryEmailPopup from './components/ProfileAuth/RecoveryEmailPopup'
import ChangePasswordPopup from './components/ChangePasswordPopup'
import HeadInfo from './components/HeadInfo'
import Header from './components/Header'
import DeskList from './components/DeskList'
import ContentTopAd from './components/Ads/ContentTopAd'
import Sidebar from './components/Sidebar'
import SubjectMenu from './components/SubjectMenu'
import ContentBottomAd from './components/Ads/ContentBottomAd'
import Footer from './components/Footer'
import Chat from './components/Chat'
import DarkSwitcher from './components/DarkSwitcher'
import { indexPaths } from './App.constants'
import './App.scss'
import AdBlockBlockMessage from './components/Ads/ContentTopAd/AdBlockBlockMessage'

const [
  Teacher,
  GuestBook,
  Archive,
  Register,
  IndividualProfile,
  TeacherTest,
  TeacherPupil,
  TeacherJournal,
  Catalog,
  Generic,
  Test
] = [
  lazy(() => import('pages/Exp/TeacherPage')),
  lazy(() => import('pages/GuestBookPage')),
  lazy(() => import('pages/ArchivePage')),
  lazy(() => import('pages/RegisterPage')),
  lazy(() => import('pages/TeacherPage/IndividualProfilePage')),
  lazy(() => import('pages/TeacherTest/TeacherTest')),
  lazy(() => import('pages/TeacherPupil/TeacherPupil')),
  lazy(() => import('pages/TeacherJournal/TeacherJournal')),
  lazy(() => import('pages/Catalog/Catalog')),
  lazy(() => import('pages/GenericPage')),
  lazy(() => import('pages/Test/Test')),
]

const useMutationObserver = (
  ref: any,
  callback: any,
  options = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  }
) => {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => observer.disconnect();
    }
  }, [callback, options]);
};

const App: FC = () => {
  const refAdTop = useRef<HTMLDivElement | null>(null);
  const refLayoutMain = useRef<HTMLDivElement | null>(null);

  const handleMutation = () => {
    let node: any = refLayoutMain.current;
    if (node !== null) {
      if (noAds === false && !window.location.hostname.includes('reshuct')) {
        //node?.firstElementChild?.nextElementSibling?.children.length !== 1
        try {
          if (getComputedStyle(node.firstElementChild!.nextElementSibling!).getPropertyValue('position') !== 'static') {
            setReasonAdblock('1')
            setDetectorAdBlock2(true);
          } else if (getComputedStyle(node.firstElementChild!.nextElementSibling!.firstChild).getPropertyValue('position') !== 'static') {
            setDetectorAdBlock2(true);
            setReasonAdblock('2')
          } else if (getComputedStyle(node.firstElementChild!.nextElementSibling).getPropertyValue('display') !== 'block') {
            setDetectorAdBlock2(true);
            setReasonAdblock('3')
          } else if (node.firstElementChild!.nextElementSibling.getBoundingClientRect().height < 85 || isNaN(node.firstElementChild!.nextElementSibling.getBoundingClientRect().height)) {
            setDetectorAdBlock2(true);
            setReasonAdblock('4')
          } else if (node.firstElementChild!.nextElementSibling.className !== '') {
            setDetectorAdBlock2(true);
            setReasonAdblock('5')
          } else if (getComputedStyle(node.firstElementChild!.nextElementSibling).getPropertyValue('margin-top').slice(0,1) === '-') {
            setDetectorAdBlock2(true);
            setReasonAdblock('6')
          } else if (getComputedStyle(node.firstElementChild!.nextElementSibling).getPropertyValue('margin-bottom').slice(0,1) === '-') {
            setDetectorAdBlock2(true);
            setReasonAdblock('7')
          } else if (getComputedStyle(node.firstElementChild!.nextElementSibling).getPropertyValue('margin').slice(0,1) === '-') {
            setDetectorAdBlock2(true);
            setReasonAdblock('8')
          } else if (node.firstElementChild!.nextElementSibling.attributes.length > 0) {
            setDetectorAdBlock2(true);
            setReasonAdblock('9')
          } else if (getComputedStyle(node.firstElementChild).getPropertyValue('margin-top').slice(0,1) === '-') {
            setDetectorAdBlock2(true);
            setReasonAdblock('14')
          } else if (getComputedStyle(node.firstElementChild).getPropertyValue('margin-bottom').slice(0,1) === '-') {
            setDetectorAdBlock2(true);
            setReasonAdblock('17')
          } else if (getComputedStyle(node.firstElementChild!.nextElementSibling!.nextElementSibling).getPropertyValue('margin-top').slice(0,1) === '-') {
            setDetectorAdBlock2(true);
            setReasonAdblock('18')
          } else if (getComputedStyle(node.firstElementChild!.nextElementSibling!.firstElementChild).getPropertyValue('margin-top').slice(0,1) === '-') {
            setDetectorAdBlock2(true);
            setReasonAdblock('19')
          } else if (getComputedStyle(node).getPropertyValue('margin-top').slice(0,1) === '-') {
            setDetectorAdBlock2(true);
            setReasonAdblock('20')
          } else if (node.firstElementChild.nextElementSibling.getClientRects()[0].height < 85) {
            setDetectorAdBlock2(true);
            setReasonAdblock('21')
          }

          const mainTopHeight = node.getBoundingClientRect().top
          const adBlockTop = node.firstElementChild!.nextElementSibling.getBoundingClientRect().top
          const nextBlock = node.firstElementChild!.nextElementSibling.nextElementSibling

          if (adBlockTop < mainTopHeight) {
            setDetectorAdBlock2(true);
            setReasonAdblock('16')
          }

          if (nextBlock) {
            const nextBlockTop = node.firstElementChild!.nextElementSibling.nextElementSibling.getBoundingClientRect().top
            if (Math.abs(adBlockTop - nextBlockTop) < 5) {
              setDetectorAdBlock2(true);
              setReasonAdblock('10')
            }
          }
          
        } catch (err) {
          setReasonAdblock('15')
          setDetectorAdBlock2(true);
          //console.log(err)
        }        
      }          
    }
  };

  useMutationObserver(refLayoutMain, handleMutation);

  const loaded = useSelector(selectSubject)
  const isAuthorized = useSelector(selectAuthStatus)
  const domain = useSelector(getDomain)
  const noAds = domain.includes('skladu') || domain.includes('reshuolymp')|| domain.includes('urok') || domain.includes('olymp')
  let isMNT = domain.includes('skladu')

  const adBlockDetected = useDetectAdBlock();
  const [activeBlockAdBlock, setActiveBlockAdBlock] = useState(false);
  const [detectorAdBlock2, setDetectorAdBlock2] = useState(false);
  const [reasonAdblock, setReasonAdblock] = useState('0');
  //const [heightAd, setHeightAd] = useState(0);
  let changedAdBlock = useRef(false);
  
  useEffect(() => {
    if (!changedAdBlock.current && (adBlockDetected || detectorAdBlock2)) {
      setActiveBlockAdBlock(document.cookie.indexOf('aabtesting=1') === -1);
      changedAdBlock.current = true;
      if (adBlockDetected || detectorAdBlock2) {
        setTimeout(() => setActiveBlockAdBlock(false), 20000)
      }
    }
  }, [adBlockDetected, detectorAdBlock2])

  useEffect(() => {
    const url = "https://yandex.ru/ads/system/context.js";
    fetch(url, {
        method: "GET",
        // mode: "no-cors",
        cache: "default",
    }).then((resp)=>{
      resp.text().then((text)=> {
        if (text) {
          if (text.length < 10000) {
            setDetectorAdBlock2(true);
            setReasonAdblock('11')
          } else {

              /*setTimeout(function(){
                if (heightAd === 0 && noAds === false && !window.location.hostname.includes('reshuct')) {
                  //setDetectorAdBlock2(true);
                  //console.log('adblock detect')
                }
              }, 5000)
            
              setTimeout(function(){
                if (noAds === false && !window.location.hostname.includes('reshuct')) {
                  let yandex_banner_f = document.querySelectorAll('div[id="yandex_rtb_R-A-154002-8"]');
                  let yandex_banner_s = document.querySelectorAll('div[id="yandex_rtb_R-A-154002-2"]');
                  //console.log('yandex_banner_f[0].innerHTML === ""', yandex_banner_f[0]?.innerHTML === "")
                  //console.log('yandex_banner_s[0].innerHTML === ""', yandex_banner_s[0]?.innerHTML === "")
                  if (yandex_banner_f.length === 0 && yandex_banner_s.length === 0) {
                    setDetectorAdBlock2(true);
                  } else if (yandex_banner_f.length > 0) {
                    if (yandex_banner_f[0]?.innerHTML === "" || getComputedStyle(yandex_banner_f[0]).display === 'none') {
                      setDetectorAdBlock2(true);
                    } else {
                      document.cookie = 'bda = 1; Max-Age=0;secure;path="/"';
                    }
                  } else {
                    if (yandex_banner_s.length > 0) {
                      if (yandex_banner_s[0]?.innerHTML === "" || getComputedStyle(yandex_banner_s[0]).display === 'none') {
                        setDetectorAdBlock2(true);
                      } else {
                        document.cookie = 'bda = 1; Max-Age=0;secure;path="/"';
                      }
                    }
                  }   
                } else {
                  document.cookie = 'bda = 1; Max-Age=0;secure;path="/"';
                }          
              }, 5001);*/
                    

            
          }
        } else {
          setDetectorAdBlock2(true);
          setReasonAdblock('12')
        }
      })
    })
      .catch(() => {
          //setDetectorAdBlock2(true);
      });
}, [noAds]);


useEffect(() => {
  let node = refAdTop.current;
  if (node !== null) {
    if (noAds === false && !window.location.hostname.includes('reshu')) {
      if (node?.getBoundingClientRect().height < 30) {
        setDetectorAdBlock2(true);
        setReasonAdblock('13')
      }
    }
  }
}, [noAds, refAdTop, loaded])
 
  useGeneralData()

  if (!loaded) {
    return (
      <Portal>
        <Spinner />
      </Portal>
    )
  }

  return (
    <>{activeBlockAdBlock ? <AdBlockBlockMessage reasonAdblock={reasonAdblock}/> :
    <div className="App">
      <div className="PageLayout-IsMobile"><DarkSwitcher/></div>
    
      <HeadInfo />

      <div className="PageLayout StartPage">
        <Header className="PageLayout-Header" />

        {!isMNT && <SubjectMenu className="PageLayout-Nav" />}

        <div className="PageLayout-Grid">
          <Sidebar />

          <div className="PageLayout-Content">
            <main className="PageLayout-Main" ref={refLayoutMain}>
              <DeskList />

              {!noAds && <div ref={refAdTop}><ContentTopAd /></div>}

              <Routes>
                {indexPaths.map((e, i) => <Route key={i} path={e} element={<Student/>} />)}
                  <Route path="/test" element={<React.Suspense fallback={ContentFallback}><Test/></React.Suspense>} />
                  <Route path="/guestbook" element={<React.Suspense fallback={ContentFallback}><GuestBook/></React.Suspense>} />
                  <Route path="/about" element={<React.Suspense fallback={ContentFallback}><Generic name="about"/></React.Suspense>}/>
                  <Route path="/staff" element={<React.Suspense fallback={ContentFallback}><Generic name="staff"/></React.Suspense>}/>
                  <Route path="/legal" element={<React.Suspense fallback={ContentFallback}><Generic name="legal"/></React.Suspense>}/>
                  <Route path="/licence" element={<React.Suspense fallback={ContentFallback}><Generic name="licence"/></React.Suspense>}/>
                  <Route path="/privacy" element={<React.Suspense fallback={ContentFallback}><Generic name="privacy"/></React.Suspense>}/>
                  <Route path="/copyright" element={<React.Suspense fallback={ContentFallback}><Generic name="copyright"/></React.Suspense>}/>
                  <Route path="/advert" element={<React.Suspense fallback={ContentFallback}><Generic name="advert"/></React.Suspense>}/>
                  {/*<Route path="/test" element={<React.Suspense fallback={ContentFallback}><Test/></React.Suspense>} />*/}
                  <Route path="/archive" element={<React.Suspense fallback={ContentFallback}><Archive/></React.Suspense>} />
                  <Route path="/register" element={<React.Suspense fallback={ContentFallback}><Register/></React.Suspense>} />
                  <Route path="/teacher/individual-profile" element={<React.Suspense fallback={ContentFallback}><IndividualProfile/></React.Suspense>}/>
                  <Route path="/teacher/test" element={<React.Suspense fallback={ContentFallback}><TeacherTest/></React.Suspense>} />
                  <Route path="/teacher/pupil" element={<React.Suspense fallback={ContentFallback}><TeacherPupil/></React.Suspense>} />
                  <Route path="/teacher/journal" element={<React.Suspense fallback={ContentFallback}><TeacherJournal/></React.Suspense>} />
                  <Route path="/prob-catalog" element={<React.Suspense fallback={ContentFallback}><Catalog/></React.Suspense>} />
                  <Route path="/variant" element={<React.Suspense fallback={ContentFallback}><Test/></React.Suspense>} />
                  {/* NOT FOR PRODUCTION */}
                  <Route path="/teacher_" element={<React.Suspense fallback={ContentFallback}><Teacher/></React.Suspense>} />
                  {/* <Redirect to="/" /> come back soon */}
              </Routes>
              <RecoveryEmailPopup />
              <ChangePasswordPopup />

              <ContentBottomAd />
            </main>

            {!isMNT && <Footer className="PageLayout-Footer" />}
          </div>
        </div>
      </div>

      <Portal>
        <AlertList>
          {isAuthorized ? <AlertListChat /> : <AlertListAuth />}
        </AlertList>

        {isAuthorized && <Chat />}
      </Portal>
    </div>}
    </>
  )
}

export default App
