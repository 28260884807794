import { IExam } from 'modules/Exam'

export const ExamList: Array<IExam> = [
  { title: 'ЕГЭ', name: 'ege', domain: 'ege.sdamgia.ru' },
  { title: 'ОГЭ', name: 'oge', domain: 'oge.sdamgia.ru' },
  { title: 'ГВЭ', name: 'gve', domain: 'gve.sdamgia.ru' },
  { title: 'ВПР', name: 'vpr', domain: 'vpr.sdamgia.ru' },
  { title: 'Урок', name: 'urok', domain: 'urok.sdamgia.ru' },
  { title: 'Олимп', name: 'olymp', domain: 'olymp.sdamgia.ru' },
  { title: 'ЦТ и ЦЭ', name: 'ct', domain: 'reshu.by' },
  { title: 'ЕНТ', name: 'ent', domain: 'reshuent.kz' },
  { title: 'СПО', name: 'spo', domain: 'spo.sdamgia.ru' }
]
